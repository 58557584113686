import { Options } from "vue-class-component";
import SelectLevel from "@/modules/select-level/select-level.vue";
import API from "@/api/project/project-attribute";
import ProjectAttributeMultipleAPI from "@/api/project/project-attribute-multiple";
import BaseFunction from "@/mixins/BaseFunction";
import TableRequestItem from "@/entity/TableRequestItem";
import ProjectAPI from "@/api/project/project-list";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import AttributeCommonCard from "@/modules/attribute-common-card/attribute-common-card.vue";
import AttributePartCard from "@/modules/attribute-part-card/attribute-part-card.vue";
import TablePage from "@/modules/table-page/table-page.vue";
import ProjectListTableItem from "@/entity/project/ProjectListTableItem";
import { ModelObjectOptions } from "@/entity/project/ProjectControl";
import TablePageItems from "@/entity/TablePageItems";

@Options({
    name: "app-project-project-attribute",
    components: {
        "app-table-page": TablePage,
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
        "app-attribute-common-card": AttributeCommonCard,
        "app-attribute-part-card": AttributePartCard,
    },
})
export default class ProjectAttribute extends BaseFunction {
    //Tab索引
    private tabIndex: string | number = "";
    //加载中
    private isLoading = false;
    //选中层号
    private layerId = 0;
    //层数选项
    private projectLevelOptions: any[] = [];
    //选择器请求Item
    private tableRequestItem: TableRequestItem = new TableRequestItem();
    //设备信息
    private projectInfo: ProjectListTableItem = new ProjectListTableItem();
    //编辑model
    private editModalItem: ProjectListTableItem = new ProjectListTableItem();
    //属性信息
    private projectModelAttribute: any = {};
    //属性值信息
    private projectModelAttributeValues: any = {};
    private tablePageItems = new TablePageItems();

    public created(): void {
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editModalItem = new ProjectListTableItem();
    }

    public getLockItem(): any {
        return {
            projectId: {
                type: "eq",
                name: "p.project_id",
                title: "所属设备",
                parent: this.authUser.orgCode,
                value: 210901,
                openFn: this.projectIdLevelModalOpen,
            },
        };
    }

    public async activated(): Promise<void> {
        await this.getList();
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //ProjectAPI
    get getProjectApi(): any {
        return ProjectAPI;
    }

    get getProjectAttributeMultipleAPI(): any {
        return ProjectAttributeMultipleAPI;
    }

    //模型对象名称
    public modelObjectName(value: number) {
        return ModelObjectOptions.find((item: any) => item.value === value)?.label;
    }

    //获取属性数据
    public async getList() {
        const request: any = {
            projectId: this.projectInfo.projectId,
            projectName: this.projectInfo.projectName,
            orgCode: this.projectInfo.orgCode,
            layerId: this.layerId,
        };
        this.isLoading = true;
        await API.list(request)
            .then((res: any) => {
                if (res.data && res.data.projectInfo) {
                    this.projectInfo = res.data.projectInfo;
                    this.tableRequestItem.lockItem.projectId.value = this.projectInfo.projectId;
                    this.tableRequestItem.lockItem.projectId.title = this.projectInfo.projectName;
                    this.tableRequestItem.lockItem.projectId.parent = this.projectInfo.orgCode;
                }

                if (res.data) {
                    this.layerId = res.data.layerId;
                    this.projectLevelOptions = res.data.projectLevelOptions;
                }

                if (res.data && res.data.projectModelAttributeValues) {
                    this.projectModelAttributeValues = res.data.projectModelAttributeValues;
                }

                if (res.data && res.data.projectModelAttribute) {
                    const child = res.data.projectModelAttribute.child;
                    if (child && child.length > 0) {
                        this.projectModelAttribute = res.data.projectModelAttribute;
                        this.tabIndex = this.projectModelAttribute.child[0].modelCode;
                    } else {
                        this.projectModelAttribute = [];
                        this.tabIndex = "";
                    }
                } else {
                    this.projectModelAttribute = [];
                    this.tabIndex = "";
                }
                //加载多例属性
                this.loadAttributeMultiple(this.projectModelAttribute);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    //projectId搜索字段
    private projectIdLevelSearchField: any = [
        { field: "project_name", name: "名称" },
        { field: "project_remark", name: "说明" },
    ];
    //projectId层级选择器打开
    public projectIdLevelModalOpen() {
        (this.$refs.projectIdLevel as any).openModal();
    }
    //projectId层级选择器提交
    public async projectIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().projectId : item;
        this.tableRequestItem.lockItem.projectId.value = curItem.value;
        this.tableRequestItem.lockItem.projectId.title = curItem.title;
        this.tableRequestItem.lockItem.projectId.parent = curItem.parent;

        //console.log(this.currentProjectInfo);
        this.projectInfo.projectId = curItem.value;
        this.projectInfo.projectName = curItem.title;
        this.projectInfo.orgCode = curItem.parent;
        this.layerId = 1;
        await this.getList();
        return Promise.resolve();
    }

    //层数改变事件
    public async layerIdChange() {
        await this.getList();
    }

    //修改单例属性事件
    public editAttributeSingleModalOpen() {}

    //多例属性翻页程序
    private attributeMultipleFunction: any = {};
    private attributeMultipleTablePageItems: any = {};
    private attributeMultipleTableTableItems: any = {};
    //多例属性递归
    private loadAttributeMultiple(item: any) {
        if (this.$tools.isEmpty(item)) return;
        if (item.modelObject == "multiple") {
            const modelId = item.modelId.toString();
            this.attributeMultipleTablePageItems[modelId] = new TablePageItems();
            this.attributeMultipleTableTableItems[modelId] = {};
            this.attributeMultipleFunction[modelId] = (page: number) => this.getAttributeMultipleList(item.modelId, page);
            this.attributeMultipleFunction[modelId](1);
        } else {
            if (!Array.isArray(item.child) || item.child.length < 1) return;
            for (let i = 0; i < item.child.length; i++) {
                this.loadAttributeMultiple(item.child[i]);
            }
        }
    }
    //加载多例属性数据
    public getAttributeMultipleList(modelId: number, page: number) {
        if (page > 0) this.attributeMultipleTablePageItems[modelId].page = page;
        this.attributeMultipleTablePageItems[modelId].pageSize = 8;
        this.attributeMultipleTablePageItems[modelId].modelId = modelId;
        this.attributeMultipleTablePageItems[modelId].projectId = this.projectInfo.projectId;
        this.attributeMultipleTablePageItems[modelId].layerId = this.layerId;
        this.getProjectAttributeMultipleAPI
            .list(this.attributeMultipleTablePageItems[modelId])
            .then((res: any) => {
                this.attributeMultipleTablePageItems[modelId].total = res.data.total;
                this.attributeMultipleTablePageItems[modelId].pages = res.data.pages;
                this.attributeMultipleTablePageItems[modelId].current = res.data.current;
                this.attributeMultipleTablePageItems[modelId].size = res.data.size;
                this.attributeMultipleTableTableItems[modelId] = res.data.records;
            })
            .finally(() => {
                this.isLoading = false;
            });
        return true;
    }

    //添加多例属性事件
    private attributeMultipleEditType = "";
    private attributeMultipleEditModalTitle = "";
    private attributeMultipleEditModalSubmitButtonName = "";
    private attributeMultipleEditModalField: any = {};
    private attributeMultipleEditModalItem: any = {};
    public addAttributeMultipleModalOpen(item: any) {
        if (this.$tools.isEmpty(item) || this.$tools.isEmpty(item.attributeJson)) {
            this.toast.error("属性字段未配置");
            return;
        }
        this.attributeMultipleEditType = "add";
        this.attributeMultipleEditModalSubmitButtonName = "添加";
        this.attributeMultipleEditModalTitle = `${this.attributeMultipleEditModalSubmitButtonName}${item.modelName}数据`;
        this.attributeMultipleEditModalField = item.attributeJson;
        this.attributeMultipleEditModalItem = {
            projectId: this.projectInfo.projectId,
            layerId: this.layerId,
            modelId: item.modelId,
            statusId: 1,
            sortId: 999,
            attributeJson: {},
        };
        (this.$refs.editAttribute as any).openModal(false);
    }

    //修改多例属性
    public modAttributeMultipleModalOpen(modelItem: any, attributeItem: any) {
        if (this.$tools.isEmpty(modelItem) || this.$tools.isEmpty(modelItem.attributeJson)) {
            this.toast.error("属性字段未配置");
            return;
        }
        this.attributeMultipleEditType = "mod";
        this.attributeMultipleEditModalSubmitButtonName = "修改";
        this.attributeMultipleEditModalTitle = `${this.attributeMultipleEditModalSubmitButtonName}${modelItem.modelName}数据`;
        this.attributeMultipleEditModalField = modelItem.attributeJson;
        this.attributeMultipleEditModalItem = JSON.parse(JSON.stringify(attributeItem));
        (this.$refs.editAttribute as any).openModal(false);
    }

    //编辑多例属性提交
    public async editAttributeMultipleSubmit(): Promise<boolean> {
        let isOk = false;
        Object.keys(this.attributeMultipleEditModalItem.attributeJson).forEach((key) => {
            const value = this.attributeMultipleEditModalItem.attributeJson[key];
            if (!this.$tools.isEmpty(value)) isOk = true;
        });
        if (!isOk) {
            this.toast.error("全部数据值为空，请输入有效值");
            return Promise.reject(false);
        }
        if (this.attributeMultipleEditType == "add") {
            await this.getProjectAttributeMultipleAPI.addAttribute(this.attributeMultipleEditModalItem).then((res: any) => {
                this.toast.success(res.msg);
                this.getAttributeMultipleList(this.attributeMultipleEditModalItem.modelId, 0);
            });
        } else if (this.attributeMultipleEditType == "mod") {
            await this.getProjectAttributeMultipleAPI.modAttribute(this.attributeMultipleEditModalItem).then((res: any) => {
                this.toast.success(res.msg);
                this.getAttributeMultipleList(this.attributeMultipleEditModalItem.modelId, 0);
            });
        } else {
            this.toast.error("编辑类型错误");
            return Promise.reject(false);
        }
        return Promise.resolve(true);
    }
    //删除多例属性
    public delAttributeMultipleModalOpen(item: any) {
        const delAlertConfig = {
            type: "okAndNo",
            title: "删除",
            message: "请确认删除？",
            submitButtonName: "删除",
            fixed: false,
        };
        (this.$refs.delAttributeMultipleModal as any).openAlert(delAlertConfig, async () => {
            await this.getProjectAttributeMultipleAPI.delAttribute({ attributeId: item.attributeId }).then((res: any) => {
                this.toast.success(res.msg);
                this.getAttributeMultipleList(item.modelId, 0);
            });
            return Promise.resolve(true);
        });
    }

    //多例属性状态切换
    public async attributeMultipleToggleStatus(item: any) {
        const statusAlertConfig = {
            title: "状态",
            message: "请确认修改状态？",
            closeButtonName: "取消",
            submitButtonName: "提交",
        };
        this.$vueAlert(statusAlertConfig)
            .then(async () => {
                await this.getProjectAttributeMultipleAPI.statusAttribute({ attributeId: item.attributeId, statusId: item.statusId === 1 ? 2 : 1 }).then((res: any) => {
                    this.toast.success(res.msg);
                    this.getAttributeMultipleList(item.modelId, 0);
                });
            })
            .catch(() => {
                this.isLoading = false;
            });
    }

    public attributeMultipleEditSortBaseTitle = "排序";
    public attributeMultipleEditSortTitle: string = this.attributeMultipleEditSortBaseTitle;
    public attributeMultipleEditSortList: any = [];
    //多例属性获取排序数据
    public attributeMultipleSortListView(modelItem: any, attributeItem: any) {
        this.attributeMultipleEditModalItem = attributeItem;
        this.getProjectAttributeMultipleAPI.sortAttribute(attributeItem).then((res: any) => {
            this.attributeMultipleEditSortTitle = this.attributeMultipleEditSortBaseTitle + ": " + modelItem.modelName;
            this.attributeMultipleEditSortList = res.data.list;
            (this.$refs.sortAttributeMultipleModal as any).openModal(false);
        });
    }

    //多例属性排序保存
    public async tableAttributeMultipleSortSubmit(): Promise<boolean> {
        if (this.attributeMultipleEditSortList < 1) {
            this.toast.error("排序数据不能为空！");
            return Promise.reject(false);
        }
        await this.getProjectAttributeMultipleAPI.sortAttributeSave(this.attributeMultipleEditSortList).then((res: any) => {
            this.toast.success(res.msg);
            this.getAttributeMultipleList(this.attributeMultipleEditModalItem.modelId, 0);
        });
        return Promise.resolve(true);
    }

    //获取modelId字符串
    public getModelIdIndex(item: any) {
        return item.modelId.toString();
    }

    //计算表格列数
    public getTableTrLength(that: any) {
        if (that && that.length === 1) {
            const tableTh = (that[0] as HTMLElement).getElementsByTagName("th");
            if (tableTh) {
                return tableTh.length;
            } else {
                return 20;
            }
        } else {
            return 20;
        }
    }
}
