import { Options } from "vue-class-component";
import ProjectModelAttributeItem from "@/entity/project/ProjectModelAttributeItem";
import API from "@/api/project/project-attribute";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "app-attribute-common-card",
    props: {
        projectId: {
            type: Number,
            default: 0,
        },
        layerId: {
            type: Number,
            default: 0,
        },
        formModelId: {
            type: Number,
            default: 0,
        },
        formModelType: {
            type: String,
            default: "",
        },
        modelId: {
            type: Number,
            default: 0,
        },
        attributeItem: {
            type: [ProjectModelAttributeItem, Object],
            default: {},
        },
        attributeValue: {
            type: Object,
            default: {},
        },
    },
})
export default class AttributeCommonCard extends BaseFunction {
    private modelId = 0;
    private attributeItem: ProjectModelAttributeItem = new ProjectModelAttributeItem();
    private attributeValue: any = {};
    private editing = false;
    public newValue = this.value;
    public isButtonClicked = false;
    public isLoading = false;
    public projectId = 0;
    public layerId = 0;
    get value(): any {
        if (this.attributeValue && this.attributeValue[this.modelId.toString()] && this.attributeValue[this.modelId.toString()][this.attributeItem.code]) {
            return this.attributeValue[this.modelId.toString()][this.attributeItem.code];
        } else {
            return null;
        }
    }

    set value(str: any) {
        if (!this.attributeValue) return;
        if (!this.attributeValue[this.modelId.toString()]) this.attributeValue[this.modelId.toString()] = {};
        if (this.$tools.isEmpty(str)) {
            delete this.attributeValue[this.modelId.toString()][this.attributeItem.code];
        } else {
            this.attributeValue[this.modelId.toString()][this.attributeItem.code] = str;
        }
    }

    public delValue(isDel: boolean): boolean {
        if (this.attributeValue && this.attributeValue[this.modelId.toString()] && this.attributeValue[this.modelId.toString()][this.attributeItem.code]) {
            if (isDel) {
                this.value = this.newValue = null;
                delete this.attributeValue[this.modelId.toString()][this.attributeItem.code];
            }
            return true;
        } else {
            this.toast.success("属性值不存在");
            return false;
        }
    }

    public handleOpen() {
        this.newValue = this.value;
        this.editing = !this.editing;
        if (this.editing) {
            this.$nextTick(() => {
                if (this.$refs.editInput) {
                    (this.$refs.editInput as any).focus();
                    (this.$refs.editInput as any).select();
                }
            }).then();
        }
    }
    //取消
    public handleBlur(event: any) {
        // 检查事件是否来源于 input 元素
        if (event.target.tagName.toLowerCase() === "input" && this.isButtonClicked) {
            this.isButtonClicked = false;
            return; // 不执行取消逻辑
        }
        // 执行取消逻辑
        this.editing = false;
    }
    //事件委托
    public handleBlurCapture(event: any) {
        // 检查事件是否来源于 button 元素
        if (event.relatedTarget && event.relatedTarget.tagName.toLowerCase() === "button") {
            this.isButtonClicked = true;
        }
    }//提交
    public async handleSubmit(event: any) {
        event.preventDefault();
        const request: any = {
            projectId: this.projectId,
            layerId: this.layerId,
            modelId: this.modelId,
            attributeCode: this.attributeItem.code,
            attributeType: this.attributeItem.type,
            attributeValue: this.newValue,
        };
        this.isLoading = true;
        await API.editAttribute(request)
            .then((res: any) => {
                this.value = this.newValue;
                this.editing = false;
                this.toast.success(res.msg);
            })
            .finally(() => {
                this.isLoading = false;
            });
        setTimeout(() => {
            this.isButtonClicked = false; // 确保在提交逻辑完成后清除标志位
        }, 0);
    }
    //删除
    public async handleDelete(event: any) {
        event.preventDefault();
        if (this.delValue(false)) {
            const request: any = {
                projectId: this.projectId,
                layerId: this.layerId,
                modelId: this.modelId,
                attributeCode: this.attributeItem.code,
            };
            this.isLoading = true;
            await API.delAttribute(request)
                .then((res: any) => {
                    this.delValue(true);
                    this.editing = false;
                    this.toast.success(res.msg);
                })
                .finally(() => {
                    this.isLoading = false;
                });
            setTimeout(() => {
                this.isButtonClicked = false; // 确保在提交逻辑完成后清除标志位
            }, 0);
        }
    }
}
