import requests from "@/utils/axios";

export default {
    // 模块URL
    modulesUrl: "/project/project/attribute",
    // 列表
    list(query: any) {
        return requests.post(this.modulesUrl + "/list", query);
    },
    // 编辑属性
    editAttribute(query: any) {
        return requests.post(this.modulesUrl + "/editAttribute", query);
    },
    // 删除属性
    delAttribute(query: any) {
        return requests.post(this.modulesUrl + "/delAttribute", query);
    },
};
