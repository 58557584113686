import requests from "@/utils/axios";

export default {
    // 模块URL
    modulesUrl: "/project/project/attribute/multiple",
    // 列表
    list(query: any) {
        return requests.post(this.modulesUrl + "/list", query);
    },
    // 添加属性
    addAttribute(query: any) {
        return requests.post(this.modulesUrl + "/addAttribute", query);
    },
    // 修改属性
    modAttribute(query: any) {
        return requests.post(this.modulesUrl + "/modAttribute", query);
    },
    // 删除属性
    delAttribute(query: any) {
        return requests.post(this.modulesUrl + "/delAttribute", query);
    },
    // 状态切换
    statusAttribute(query: any) {
        return requests.post(this.modulesUrl + "/statusAttribute", query);
    },
    // 排序展示
    sortAttribute(query: any) {
        return requests.post(this.modulesUrl + "/sortAttribute", query);
    },
    // 排序保存
    sortAttributeSave(query: any) {
        return requests.post(this.modulesUrl + "/sortAttributeSave", query);
    },
};
